/*
 * Global application theme.
 * Framework overrides and customization goes here.
*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

@import "./maramataka.scss";

@font-face {
  font-family: "PPRightGroteskTextWideRegular";
  src: url("/assets/fonts/PPRightGroteskText-WideRegular.eot");
  src: url("/assets/fonts/PPRightGroteskText-WideRegular.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/PPRightGroteskText-WideRegular.woff") format("woff"),
    url("/assets/fonts/PPRightGroteskText-WideRegular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PPRightGroteskTextWideRegularItalic";
  src: url("/assets/fonts/PPRightGroteskText-WideRegularItalic.eot");
  src: url("/assets/fonts/PPRightGroteskText-WideRegularItalic.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/PPRightGroteskText-WideRegularItalic.woff") format("woff"),
    url("/assets/fonts/PPRightGroteskText-WideRegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PPRightGroteskTextWideBold";
  src: url("/assets/fonts/PPRightGroteskText-WideBold.eot");
  src: url("/assets/fonts/PPRightGroteskText-WideBold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/PPRightGroteskText-WideBold.woff") format("woff"),
    url("/assets/fonts/PPRightGroteskText-WideBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PPRightGroteskTextWideMedium";
  src: url("/assets/fonts/PPRightGroteskText-WideMedium.eot");
  src: url("/assets/fonts/PPRightGroteskText-WideMedium.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/PPRightGroteskText-WideMedium.woff") format("woff"),
    url("/assets/fonts/PPRightGroteskText-WideMedium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PPRightGroteskTextWideLight";
  src: url("/assets/fonts/PPRightGroteskText-WideLight.eot");
  src: url("/assets/fonts/PPRightGroteskText-WideLight.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/PPRightGroteskText-WideLight.woff") format("woff"),
    url("/assets/fonts/PPRightGroteskText-WideLight.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: $black;

  @media (max-width: 1199px) {
    background-size: inherit;
  }
}

h1,
h2,
h3 {
  font-family: "PPRightGroteskTextWideMedium";
  font-weight: 500;
  // letter-spacing: -4px;
}

h4,
h5,
h6 {
  font-weight: 300;
}

a {
  transition: all ease-in 0.2s;
}

.btn {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: $white;
  padding: 6px 12px;
  border-radius: 8px 8px 0 0;
  color: $teal-dark;
}

.mat-error {
  font-family: "Inter", sans-serif;
  font-size: 13px;
}

.mat-radio-button,
.mat-chekcbox {
  font-family: "Inter", sans-serif;
}

.mainNavigation > .mat-accordion {
  .mat-expansion-panel {
    background: transparent;
    color: $white;
    box-shadow: none;
    border-radius: 0;

    .mat-expansion-panel-header {
      font-family: "Inter", sans-serif;
      padding: 0;
      height: 25px;
      display: block;

      &:hover {
        background: none !important;
      }

      .mat-content {
        display: inline-block;
        padding-left: 5px;
      }

      .mat-expansion-indicator {
        float: left;
        padding: 0;
        transform: none !important;
        width: 8px;

        &::after {
          content: "+";
          border: 0 none;
          transform: none;
          color: $white;
          padding: 0;
          vertical-align: top;
        }
      }

      &.mat-expanded {
        .mat-expansion-indicator {
          &::after {
            content: "-";
          }
        }
      }
    }

    .mat-expansion-panel-content {
      font-family: "Inter", sans-serif;
      font-size: 15px;
      color: $white;
      font-weight: 300;
      line-height: 1.2;

      .mat-expansion-panel-body {
        padding: 0 12px 12px;

        ul {
          li {
            a {
              font-size: 15px;
              color: $white;
              font-weight: 300;
              line-height: 1.2;
              text-decoration: none;

              &:hover {
                color: $cyan;
              }
            }
          }
        }
      }
    }
  }
}

.cdk-overlay-pane {
  @media (min-width: 1199px) {
    max-width: 60vw !important;
  }

  .mat-dialog-container {
    border-radius: 0 !important;
    background-color: $gray-100;
    position: relative;
    overflow: inherit !important;

    .dialog-close-btn {
      width: 78px;
      height: 42px;
      border: 0;
      position: absolute;
      top: -28px;
      right: 0;
      outline: none;
    }

    h2 {
      font-size: 48px;
      font-weight: 700;
    }

    h3 {
      font-size: 32px;
      font-weight: 700;
    }
  }

  .mat-dialog-content {
    max-height: 75vh;
  }

  .mat-dialog-title {
    margin: 0 0 10px;
  }
}

.login-box {
  .loginLogo {
    margin-bottom: -6px;
    position: relative;
    z-index: 1;
  }

  .card {
    border: 0;
    border-radius: 0;
    background-color: $gray-100;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
    background-color: $white;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      border-top: 0;
      padding-bottom: 10px;
    }

    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
      border: 1px solid $white;
    }
  }

  .mat-checkbox-checked.mat-accent {
    .mat-checkbox-background {
      background-color: $info;
    }
  }

  .forgotPass {
    color: $gray-600;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color: $info;
    }
  }

  .loginBtn {
    margin-bottom: -16px;
  }
}

.landingPageBGContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: #00272d;

  .topBlueBg,
  .middleBlueGradient,
  .bottomBlueCurves,
  .bottomGreenBg {
    width: 100%;
    height: 100%;
    background-size: cover !important;
    position: absolute;
    left: 0;
  }

  .topBlueBg {
    background: url("/assets/landingpage-top-blue-curve.png") no-repeat;
    top: -140px;
    z-index: 1;
    animation-duration: 2s;
    animation-delay: 100ms;
  }

  .middleBlueGradient {
    background: url("/assets/landingpage-gradient-blue-bg.png") no-repeat;
    top: 30px;
    z-index: 0;
    animation-duration: 3s;
    animation-delay: 150ms;
  }

  .bottomBlueCurves {
    background: url("/assets/landingpage-blue-curves.png") no-repeat;
    bottom: -20px;
    z-index: 2;
    animation-duration: 2s;
    animation-delay: 300ms;
  }

  .bottomGreenBg {
    background: url("/assets/landingpage-layer-green.png") no-repeat;
    bottom: -120px;
    z-index: 3;
    animation-duration: 2s;
    animation-delay: 200ms;
  }
}

.landingPage {
  padding-top: 50px;
  padding-bottom: 80px;
  min-height: 100vh;
  color: $white;
  position: relative;
  z-index: 1;

  .tagCloud {
    color: $white;
    text-decoration: none;
    display: inline-block;
    --animate-duration: 2s;
    z-index: 1000;

    h2 {
      font-size: 72px;
      margin-bottom: 0;
    }

    span {
      font-size: 16px;
      margin-top: -10px;
      display: block;
    }

    &:hover {
      color: $info;
    }
  }

  &.inner {
    padding-top: 0;
    overflow: hidden;

    h2 {
      font-size: 72px;
      margin-bottom: 0;
      font-weight: 500;
    }

    h4 {
      font-size: 24px;
      font-weight: 100;
      margin-bottom: 15px;
      line-height: 1.1;
    }

    p {
      line-height: 1.2;
      font-weight: 300;

      &.caption {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .btn {
      background-color: $cyan-light;
      min-width: 180px;
      text-align: left;
      font-weight: 500;

      i {
        float: right;
        margin-top: 4px;
      }

      &:focus,
      &:focus:active {
        box-shadow: none;
      }
    }

    &.hauPage {
      background: url("/assets/landingpage-hau-bg.jpg") no-repeat;
      background-size: cover;
    }

    &.waiPage {
      background: url("/assets/landingpage-wai-bg.jpg") no-repeat;
      background-size: cover;
    }

    &.moanaPage {
      background: url("/assets/landingpage-moana-bg.jpg") no-repeat;
      background-size: cover;

      .btn {
        background-color: $blue;
        border-color: $blue;
        color: $white;
      }
    }

    &.whenuaPage {
      background: url("/assets/landingpage-whenua-bg.jpg") no-repeat;
      background-size: cover;

      .btn {
        background-color: $green;
        border-color: $green;
      }
    }

    .animalImage {
      text-align: right;

      &.hau,
      &.wai {
        &.animate__slideInRight {
          .squeezed {
            position: relative;
            left: -740px;
            top: -130px;
          }
        }
      }

      &.whenua {
        text-align: left;

        &.animate__slideInRight {
          .squeezed {
            position: relative;
            left: -50px;
            max-height: 740px;
          }
        }
      }

      &.moana {
        &.animate__slideInLeft {
          .squeezed {
            position: relative;
            right: 50px;
            max-height: 480px;
          }
        }
      }
    }

    .atmosphere {
      display: none;
      cursor: pointer;

      &.animate__animated {
        display: inline-block;
      }
    }
  }
}
