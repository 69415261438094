.calendarView {
  background-color: #0d3238;
  min-height: 100vh;
  position: relative;

  // text-align: center;

  .calendarContainer {
    position: fixed;
    left: 0;
    bottom: 35px;
    width: 100%;
    height: 640px;
    padding-right: 30px;
    background-color: #0d3238;
    overflow: hidden;

    .moonIconContainer {
      width: 1600px;
      max-width: 1920px;
      position: absolute;
      left: 0;
      top: 40px;
      right: 0;
      margin: auto;
      z-index: 3;
      text-align: center;

      .moonIcon {
        width: 40px;
        height: 40px;
        border: 2px solid #fff;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        user-select: none;

        .borderCurve {
          width: 40px;
          height: 40px;
          background: transparent;
          border-radius: 50%;
          position: absolute;
          right: -1px;
          top: -1px;
          box-shadow: inset 0px 12px #fff, inset 0px 12px 1px 1px #fff;
          -moz-box-shadow: inset 0px 12px #fff, inset 0px 12px 1px 1px #fff;
          transform: rotate(90deg);
        }
      }

      .moon {
        height: 40px;
        width: 40px;
        margin: 0 auto;
        border: 2px solid #fff;
        position: absolute;
        left: 0;
        right: 0;
        -webkit-clip-path: circle(50px at center);
        clip-path: circle(50px, 50px, 50px);
        clip-path: circle(50px at center);
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background: #ffffff;
        overflow: hidden;
      }

      .moon:before,
      .moon:after {
        border-radius: 50%;
        content: "";
        position: absolute;
        top: -4%;
        left: -4%;
        height: 108%;
        width: 108%;
      }

      .moon:before {
        background: #0d3238;
        -moz-box-shadow: inset -10px 0 7px 0px #ffffff;
        -webkit-box-shadow: inset -10px 0 7px 0px #ffffff;
        box-shadow: inset -10px 0 7px 0px #ffffff;
      }

      .moon:after {
        background: #ffffff;
        -moz-box-shadow: inset -10px 0 7px 0px #ffffff;
        -webkit-box-shadow: inset -10px 0 7px 0px #ffffff;
        box-shadow: inset -10px 0 7px 0px #ffffff;
      }

      /* Animation */
      @keyframes before-fullmoon {
        0%,
        50% {
          opacity: 1;
        }
        50.01%,
        100% {
          opacity: 0;
        }
        0%,
        24.99% {
          background: #0d3238;
        }
        25%,
        100% {
          background: #ffffff;
        }
        0% {
          -moz-box-shadow: inset 0px 0 7px 0px #ffffff;
          -webkit-box-shadow: inset 0px 0 7px 0px #ffffff;
          box-shadow: inset 0px 0 7px 0px #ffffff;
        }
        24.99% {
          -moz-box-shadow: inset 55px 0 7px 0px #ffffff;
          -webkit-box-shadow: inset 55px 0 7px 0px #ffffff;
          box-shadow: inset 55px 0 7px 0px #ffffff;
        }
        25% {
          -moz-box-shadow: inset 55px 0 7px 0px #0d3238;
          -webkit-box-shadow: inset 55px 0 7px 0px #0d3238;
          box-shadow: inset 55px 0 7px 0px #0d3238;
        }
        50%,
        100% {
          -moz-box-shadow: inset 0 0 7px 0px #0d3238;
          -webkit-box-shadow: inset 0 0 7px 0px #0d3238;
          box-shadow: inset 0 0 7px 0px #0d3238;
        }
        0%,
        50%,
        100% {
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          border-radius: 50%;
        }
        20%,
        30% {
          -moz-border-radius: 10% 10% 10% 10%/50% 50% 50% 50%;
          -webkit-border-radius: 10%;
          border-radius: 10% 10% 10% 10%/50% 50% 50% 50%;
        }
        25% {
          -moz-border-radius: 0;
          -webkit-border-radius: 0;
          border-radius: 0;
        }
        0%,
        24.99% {
          -moz-transform: rotate(0);
          -ms-transform: rotate(0);
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }
        25%,
        50%,
        100% {
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }

      @keyframes after-fullmoon {
        0%,
        50% {
          opacity: 0;
        }
        50.01%,
        100% {
          opacity: 1;
        }
        0%,
        50%,
        74.99% {
          background: #ffffff;
        }
        75%,
        100% {
          background: #0d3238;
        }
        0%,
        50% {
          -moz-box-shadow: inset 0px 0 7px 0px #0d3238;
          -webkit-box-shadow: inset 0px 0 7px 0px #0d3238;
          box-shadow: inset 0px 0 7px 0px #0d3238;
        }
        74.99% {
          -moz-box-shadow: inset 55px 0 7px 0px #0d3238;
          -webkit-box-shadow: inset 55px 0 7px 0px #0d3238;
          box-shadow: inset 55px 0 7px 0px #0d3238;
        }
        75% {
          -moz-box-shadow: inset 55px 0 7px 0px #ffffff;
          -webkit-box-shadow: inset 55px 0 7px 0px #ffffff;
          box-shadow: inset 55px 0 7px 0px #ffffff;
        }
        100% {
          -moz-box-shadow: inset 0 0 7px 0px #ffffff;
          -webkit-box-shadow: inset 0 0 7px 0px #ffffff;
          box-shadow: inset 0 0 7px 0px #ffffff;
        }
        0%,
        50%,
        100% {
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          border-radius: 50%;
        }
        70%,
        80% {
          -moz-border-radius: 10% 10% 10% 10%/50% 50% 50% 50%;
          -webkit-border-radius: 10%;
          border-radius: 10% 10% 10% 10%/50% 50% 50% 50%;
        }
        75% {
          -moz-border-radius: 0;
          -webkit-border-radius: 0;
          border-radius: 0;
        }
        0%,
        50%,
        74.99% {
          -moz-transform: rotate(0);
          -ms-transform: rotate(0);
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }
        75%,
        100% {
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }

      .moon:before {
        -moz-animation: before-fullmoon linear 5s infinite;
        -webkit-animation: before-fullmoon linear 5s infinite;
        animation: before-fullmoon linear 5s infinite;
      }

      .moon:after {
        -moz-animation: after-fullmoon linear 5s infinite;
        -webkit-animation: after-fullmoon linear 5s infinite;
        animation: after-fullmoon linear 5s infinite;
      }
    }

    .bottomLandFill {
      width: 1600px;
      max-width: 1920px;
      height: 100px;
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -10px;
      z-index: 8;
      text-align: center;
      pointer-events: none;
      img {
        max-width: 42%;
      }
    }

    .wheelContainer {
      position: relative;
      width: 1600px;
      height: 1600px;
      margin: 0 auto;
    }

    #wheelDiv {
      width: 1600px;
      max-width: 1920px;
      height: 1600px;
      max-height: 1920px;
      border-radius: 50%;
      position: absolute;
      top: 100px;
      left: 0;
      right: 0;
      z-index: 0;
      margin: auto;
      transition: 500ms ease-out all;

      .wheel {
        border-radius: 50%;
        position: absolute;
        overflow: hidden;
        pointer-events: none;

        ul {
          position: relative;
          left: 0;
          right: 0;
          top: 0;
          margin: auto;
          width: 100%;
          height: 100%;
          padding: 0;
          list-style: none;
          overflow: hidden;

          li {
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 50%;
            transform-origin: 0% 100%;
            border-left: 1px solid rgba(0, 0, 0, 0.2);

            .content {
              display: flex;
              justify-content: space-around;
              position: absolute;
              transform-origin: 0% 0%;
              padding: 5px;
              pointer-events: auto;

              .marker {
                width: 21px;
                height: 100%;
                cursor: pointer;

                &.empty {
                  cursor: default;
                }
              }
            }
          }
        }
      }

      .energyLevelWheel {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0.5;
        background-image: radial-gradient(#993594 67%, #0d3238 70%);
      }

      .contentWheel {
        width: 94%;
        height: 94%;
        left: 3%;
        top: 3%;
        z-index: 2;

        .dateLabel {
          font-size: 11px;
          position: absolute;
          left: 5px;
          white-space: nowrap;
        }
      }

      .mataurangaWheel {
        width: 80%;
        height: 80%;
        left: 10%;
        top: 10%;
        z-index: 5;
        background-image: radial-gradient(#006258 64%, #4db8b1 70%);
      }

      .monitoringWheel {
        width: 72%;
        height: 72%;
        left: 14%;
        top: 14%;
        z-index: 6;
        background-image: radial-gradient(#00272d 55%, #016358 75%);

        .pin {
          display: block;
          position: absolute;
          width: 18px;
          left: 0;

          &.hau {
            top: 0;
            left: -12px;
          }

          &.whenua {
            top: 40%;
            left: -40px;
            z-index: 10;
          }

          &.moana {
            top: 50%;
            left: 12px;
            z-index: 10;
          }
          &.wai {
            top: 25%;
            left: 40px;
            z-index: 10;
          }
        }
      }

      .observationsWheel {
        width: 58%;
        height: 58%;
        left: 21%;
        top: 21%;
        z-index: 7;
        // pointer-events: none;
        background: #428644;
      }

      /* Monthly View */
      // &.monthCalendarView {
      //   background-image: none;
      //   background: #0d3238;

      //   .monthView {
      //     display: block;
      //     li {
      //       .text {
      //         transform: skewY(60deg) rotate(3deg);
      //         img {
      //           position: absolute;
      //           top: 10px;
      //           width: 164px;
      //           height: 110px;
      //         }
      //       }
      //     }
      //     li:first-child {
      //       -webkit-transform: rotate(0deg) skewY(-60deg);
      //       -ms-transform: rotate(0deg) skewY(-60deg);
      //       transform: rotate(0deg) skewY(-60deg);
      //       .text {
      //         background: #ff6935;
      //       }
      //     }
      //     li:nth-child(2) {
      //       -webkit-transform: rotate(30deg) skewY(-60deg);
      //       -ms-transform: rotate(30deg) skewY(-60deg);
      //       transform: rotate(30deg) skewY(-60deg);
      //       .text {
      //         background: #ff4d3d;
      //       }
      //     }
      //     li:nth-child(3) {
      //       -webkit-transform: rotate(60deg) skewY(-60deg);
      //       -ms-transform: rotate(60deg) skewY(-60deg);
      //       transform: rotate(60deg) skewY(-60deg);
      //       .text {
      //         background: #ec252b;
      //       }
      //     }
      //     li:nth-child(4) {
      //       -webkit-transform: rotate(90deg) skewY(-60deg);
      //       -ms-transform: rotate(90deg) skewY(-60deg);
      //       transform: rotate(90deg) skewY(-60deg);
      //       .text {
      //         background: #0e5faa;
      //       }
      //     }
      //     li:nth-child(5) {
      //       -webkit-transform: rotate(120deg) skewY(-60deg);
      //       -ms-transform: rotate(120deg) skewY(-60deg);
      //       transform: rotate(120deg) skewY(-60deg);
      //       .text {
      //         background: #0082c0;
      //       }
      //     }
      //     li:nth-child(6) {
      //       -webkit-transform: rotate(150deg) skewY(-60deg);
      //       -ms-transform: rotate(150deg) skewY(-60deg);
      //       transform: rotate(150deg) skewY(-60deg);
      //       .text {
      //         background: #00bad1;
      //       }
      //     }
      //     li:nth-child(7) {
      //       -webkit-transform: rotate(180deg) skewY(-60deg);
      //       -ms-transform: rotate(180deg) skewY(-60deg);
      //       transform: rotate(180deg) skewY(-60deg);
      //       .text {
      //         background: #4eb8b1;
      //       }
      //     }
      //     li:nth-child(8) {
      //       -webkit-transform: rotate(210deg) skewY(-60deg);
      //       -ms-transform: rotate(210deg) skewY(-60deg);
      //       transform: rotate(210deg) skewY(-60deg);
      //       .text {
      //         background: #63a164;
      //       }
      //     }
      //     li:nth-child(9) {
      //       -webkit-transform: rotate(240deg) skewY(-60deg);
      //       -ms-transform: rotate(240deg) skewY(-60deg);
      //       transform: rotate(240deg) skewY(-60deg);
      //       .text {
      //         background: #428644;
      //       }
      //     }
      //     li:nth-child(10) {
      //       -webkit-transform: rotate(270deg) skewY(-60deg);
      //       -ms-transform: rotate(270deg) skewY(-60deg);
      //       transform: rotate(270deg) skewY(-60deg);
      //       .text {
      //         background: #e79700;
      //       }
      //     }
      //     li:nth-child(11) {
      //       -webkit-transform: rotate(300deg) skewY(-60deg);
      //       -ms-transform: rotate(300deg) skewY(-60deg);
      //       transform: rotate(300deg) skewY(-60deg);
      //       .text {
      //         background: #f5b903;
      //       }
      //     }
      //     li:nth-child(12) {
      //       -webkit-transform: rotate(330deg) skewY(-60deg);
      //       -ms-transform: rotate(330deg) skewY(-60deg);
      //       transform: rotate(330deg) skewY(-60deg);
      //       .text {
      //         background: #f5ab00;
      //       }
      //     }
      //   }
      //   .greenGradient {
      //     box-shadow: 0px -5px 15px #555;
      //     .markerPins {
      //       transform: rotate(0deg) skewY(60deg);
      //       top: 120px;
      //     }
      //   }
      //   .darkGreenCircle {
      //     .monthView {
      //       display: block;
      //     }
      //   }
      //   .lightGreenCircle {
      //     .markerPins {
      //       top: 65px;
      //       transform: rotate(0deg) skewY(60deg);
      //       &.pinTwo {
      //         top: 125px;
      //       }
      //     }
      //   }
      // }
    }

    &.monthCalendar {
      .sunIconContainer,
      .starConstellation {
        display: none;
        z-index: 4;
      }
    }
  }
}
